.bingeStats {

}

h2 {
    margin: 0;
}

.statRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 60px;
}

#yearChart {
    margin: 0 auto;
}

.statContainer {
    flex: 1;
}